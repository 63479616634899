// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@import "../global";
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #004494;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #00c851;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #e53935;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;


  /** organization **/
  --ion-color-organization: #00ACE6;
  --ion-color-organization-rgb: 0, 172, 230;
  --ion-color-organization-contrast: #ffffff;
  --ion-color-organization-contrast-rgb: 255, 255, 255;
  --ion-color-organization-shade: #0097CA;
  --ion-color-organization-tint: #1AB4E9;

  /** candidate **/
  --ion-color-candidate: #004494;
  --ion-color-candidate-rgb: 0, 68, 148;
  --ion-color-candidate-contrast: #ffffff;
  --ion-color-candidate-contrast-rgb: 255, 255, 255;
  --ion-color-candidate-shade: #003C82;
  --ion-color-candidate-tint: #1A579F;

}

.ion-color-candidate {
  --ion-color-base: var(--ion-color-candidate);
  --ion-color-base-rgb: var(--ion-color-candidate-rgb);
  --ion-color-contrast: var(--ion-color-candidate-contrast);
  --ion-color-contrast-rgb: var(--ion-color-candidate-contrast-rgb);
  --ion-color-shade: var(--ion-color-candidate-shade);
  --ion-color-tint: var(--ion-color-candidate-tint);
}

.ion-color-organization {
  --ion-color-base: var(--ion-color-organization);
  --ion-color-base-rgb: var(--ion-color-organization-rgb);
  --ion-color-contrast: var(--ion-color-organization-contrast);
  --ion-color-contrast-rgb: var(--ion-color-organization-contrast-rgb);
  --ion-color-shade: var(--ion-color-organization-shade);
  --ion-color-tint: var(--ion-color-organization-tint);
}

