/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


$color-matchpoint-background: #4f4f4f;
$color-matchpoint-primary: #004494;
$color-matchpoint-secondary: #ffffff;
$color-matchpoint-greytext: #4f4f4f;
$color-matchpoint-lightblue: #00ACE6;
$color-matchpoint-green: #00c851;
$color-matchpoint-red: #e53935;

.app-color-green-background{
  background-color: #00c851;

}

.app-color-red-background{
  background-color: #e53935;
}


.text-app-red{
  color: $color-matchpoint-red;
}
.text-app-green{
  color: $color-matchpoint-green;
}

.bg-app {
  background-color: $color-matchpoint-background !important;
  color: #ffffff;
  --ion-background-color: #4f4f4f;
}

.text-app-primary {
  background-color: $color-matchpoint-primary;
  color: $color-matchpoint-primary;
}

.text-app-darkblue{
  color: $color-matchpoint-primary;
}

.text-app-lightblue{
  color: $color-matchpoint-lightblue;
}

.text-app-secondary {
  color: $color-matchpoint-secondary;
}

.text-app-gray {
  color: $color-matchpoint-greytext;
}

.text-app-candidate {
  background-color: $color-matchpoint-primary;
  color: #fff;
}

.candidate-nav.tab-selected {
  color: $color-matchpoint-primary !important;;
}

.ion-modal{
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.h1-responsive {
  font-size: 150%; }

.h2-responsive {
  font-size: 145%; }

.h3-responsive {
  font-size: 135%; }

.h4-responsive {
  font-size: 135%; }

.h5-responsive {
  font-size: 135%; }

@media (min-width: 576px) {
  .h1-responsive {
    font-size: 170%; }
  .h2-responsive {
    font-size: 140%; }
  .h3-responsive {
    font-size: 125%; }
  .h4-responsive {
    font-size: 125%; }
  .h5-responsive {
    font-size: 125%; } }

@media (min-width: 768px) {
  .h1-responsive {
    font-size: 200%; }
  .h2-responsive {
    font-size: 170%; }
  .h3-responsive {
    font-size: 140%; }
  .h4-responsive {
    font-size: 125%; }
  .h5-responsive {
    font-size: 125%; } }

@media (min-width: 992px) {
  .h1-responsive {
    font-size: 200%; }
  .h2-responsive {
    font-size: 170%; }
  .h3-responsive {
    font-size: 140%; }
  .h4-responsive {
    font-size: 125%; }
  .h5-responsive {
    font-size: 125%; } }

@media (min-width: 1200px) {
  .h1-responsive {
    font-size: 250%; }
  .h2-responsive {
    font-size: 200%; }
  .h3-responsive {
    font-size: 170%; }
  .h4-responsive {
    font-size: 140%; }
  .h5-responsive {
    font-size: 125%; } }

.cursor-pointer{
  cursor: pointer;
}

.cursor-default{
  cursor: default;
}

.font-size-small{
  font-size: small;
}

.lh-14{
  line-height: 1.4rem;
}
.lh-16{
  line-height: 1.6rem;
}
